<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Refinancimiento de Crédito - Nuevo</strong>
            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/refinanciar-credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Crédito:</label>
                    <v-select @input="GetCreditByID" placeholder="Seleccione un crédito" class="w-100" :filterable="false" label="text" v-model="credit" @search="SearchCredit" :options="credits"></v-select>
                    <small v-if="errors.id_credit" class="form-text text-danger" >Seleccione un credito</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_refinance.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Seleccione un código</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo de Crédito:">
                    <b-form-input disabled type="text" class="text-center" v-model="credit_refinance.product_type"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_refinance.balance"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Pago:">
                    <b-form-input disabled type="date" class="text-center" v-model="credit_refinance.payment_date"></b-form-input>
                    <small v-if="errors.payment_date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nueva Fecha Pago :">
                    <b-form-input type="date" class="text-center" v-model="credit_refinance.new_payment_date" :min="credit_refinance.payment_date"></b-form-input>
                    <small v-if="errors.new_payment_date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Cuotas:">
                    <b-form-input type="number" step="any" class="text-right" v-model="credit_refinance.dues"></b-form-input>
                    <small v-if="errors.dues"  class="form-text text-danger" >Ingrese una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button type="button" class="form-control" variant="primary" @click="ValidateSimulate">Simular Cronograma</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Doc. Adjunto:">
                    <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_refinance.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

              

                <b-col md="12" v-if="credit_refinance.id_product == 2">
                  <div class="alert alert-dark" role="alert">
                    <strong>PAGOS DEL CAPITAL</strong> 

                    <b-button class="ml-2" size="sm" type="button" @click="AddCreditCapital" variant="primary">+</b-button>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="6%" class="text-center">#</th>
                          <th width="84%" class="text-center">Capital</th>
                          <th width="10%" class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr v-for="(item, it) in credit_refinance.payment_capital" :key="it">
                          <td class="text-center">
                            <b-form-input size="sm" @change="CalculateDiferenceCapital" type="number" step="any" class="text-center" v-model="item.dues"></b-form-input>
                          </td>
                          <td class="text-right"> 
                            <b-form-input size="sm" @change="CalculateDiferenceCapital" type="number" step="any" class="text-right" v-model="item.amount"></b-form-input>
                          </td>
                          <td class="text-center"> 
                            <b-button size="xs" variant="danger"><i class="fas fa-trash"></i></b-button>
                          </td>
                        </tr>
                        <tr v-if="parseFloat(credit_refinance.capital_diference) != 0">
                          <td></td>
                          <td class="text-right"><strong class="text-danger">{{ credit_refinance.capital_diference }}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
               
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>CRONOGRAMA DE ṔAGO</strong> 
                  </div>


                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="17%" class="text-center">Cuota</th>
                          <th width="17%" class="text-center">Fecha</th>
                          <th width="17%" class="text-center">Capital</th>
                          <th width="17%" class="text-center">Interes</th>
                          <th width="17%" class="text-center">Saldo</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit_refinance.loan_detail" :key="it">
                        <tr>
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-right"> {{ item.quota  }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </b-col>

                

               

               

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CreditRefinance',
      role: 2,
      credit_refinance: {
          id_credit_refinance:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_product:'',
          id_credit:'',
          id_credit_detail:'',
          code:'',
          product_type : '',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_date: '',
          new_payment_date: '',
          dues:'',
          annexes:'',
          observation:'',
          quota : '0.00',
          amortization : '0.00',
          total:'0.00',
          state:1,
          quota_number : '',
          balance : '',
          interest : '',
          
          loan_detail : [],
          payment_capital : [],
          capital_diference:'0.00',
      },
      file:null,
      modules:[],
      credit: null,
      credits: [],
      errors: {
        id_client: false,
        payment_date: false,
        new_payment_date: false,
        total: false,
        id_credit:false,
        id_credit_detail: false,
        quota:false,
        amortization: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetCorrelative();
  },
  methods: {
    GetCorrelative,
    GetCreditByID,
    GetCreditDetailByAmortization,

    AddCreditRefinance,
    Validate,

    SearchCredit,
    onFileChange,
    UpdateBalanceQuota,
    CalculateDiferenceCapital,


    AddCreditCapital,
    DeleteCreditCapital,
    ValidateSimulate,
    Simulation,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function AddCreditCapital() {
  this.credit_refinance.payment_capital.push({
    dues:'',
    amount:'',
  })
}

function DeleteCreditCapital(index) {
  
}

function UpdateBalanceQuota() {
  this.credit_refinance.total = this.credit_refinance.total.length == 0 ? 0 : this.credit_refinance.total;
  this.credit_refinance.total = parseFloat(this.credit_refinance.total).toFixed(2);

  for (let index = 0; index < this.credit_refinance.credits_details_agricultural.length; index++) {
    const element = this.credit_refinance.credits_details_agricultural[index];
    if (element.quota_number == this.credit_refinance.quota_number) {
        element.capital_balance = this.credit_refinance.total;
    }
  }
  this.CalculateDiferenceCapital();
}

function CalculateDiferenceCapital() {
  var capital_balance = 0;
  if (this.credit_refinance.id_product == 2) { /// credito agropecuario
    for (let index = 0; index < this.credit_refinance.payment_capital.length; index++) {
      const element = this.credit_refinance.payment_capital[index];
      element.dues = element.dues.length == 0 ? 0 : element.dues;
      element.amount = element.amount.length == 0 ? 0 : element.amount;
      element.amount = parseFloat(element.amount).toFixed(2);
      capital_balance += parseFloat(element.amount);
    }
  }

  this.credit_refinance.capital_diference = parseFloat(this.credit_refinance.balance) - parseFloat(capital_balance);
  this.credit_refinance.capital_diference = parseFloat(this.credit_refinance.capital_diference).toFixed(2);
}


function onFileChange(e) {
  this.credit_refinance.annexes = e.target.files[0];
}

function GetCorrelative() {
  let me = this;
  let url = me.url_base + "data/get-correlative/CreditRefinance";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_refinance.code = response.data.result.number;
      }
    });
}

function GetCreditDetailByAmortization() {
  let me = this;
  let url = me.url_base + "credit-amortization/credit-detail-amortization";
  if (this.client == null) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un crédito', timer: 2000,}); 
    return false;
  }
  if (this.credit_refinance.id_credit_detail.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un crédito', timer: 2000,}); 
    return false;
  }
  if (this.credit_refinance.total.length == 0 ||  parseFloat(this.credit_refinance.total) < 0) {
    Swal.fire({ icon: 'warning', text: 'Ingrese una amortización', timer: 2000,}); 
    return false;
  }

  if (parseFloat(this.credit_refinance.total) > parseFloat(this.credit_refinance.balance)) {
    Swal.fire({ icon: 'warning', text: 'El monto de la amortización no debe superar al saldo capital', timer: 2000,}); 
    return false;
  }

  if (this.credit_refinance.id_product == 2) { // credit agropecuario
    if (this.credit_refinance.capital_diference.length == 0 ||  parseFloat(this.credit_refinance.capital_diference) != 0) {
      Swal.fire({ icon: 'warning', text: 'Verifique que los pagos al capital no tengo una diferencia al saldo capital', timer: 2000,}); 
      return false;
    }
  }
  

  let data =  this.credit_refinance;
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_refinance.credits_details = response.data.result.credits_details;
        me.credit_refinance.capital_diference = '0.00';
      }else{
        Swal.fire({ icon: 'warning', text: response.data.message, timer: 2000,}); 
    return false;
      }
    });
}

function SearchCredit(search, loading) {
  
  let me = this;
   let url = this.url_base + "credit-refinance/credits-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.credits = response.data.result;
           loading(false);
     })
   }
}

function AddCreditRefinance() {
  let me = this;
  let url = me.url_base + "credit-refinance/add";
  me.isLoading = true;

  let data = new FormData();
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_user", this.user.id_user);
  data.append("id_credit", this.credit.value);
  data.append("id_credit_detail", this.credit_refinance.id_credit_detail);
  data.append("code", this.credit_refinance.code);
  data.append("balance", this.credit_refinance.balance);
  data.append("dues", this.credit_refinance.dues);
  data.append("payment_date", this.credit_refinance.payment_date);
  data.append("new_payment_date", this.credit_refinance.new_payment_date);
  data.append("observation", this.credit_refinance.observation);
  data.append("annexes", this.credit_refinance.annexes);
  data.append("payment_capital", JSON.stringify(this.credit_refinance.payment_capital));

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.$router.push({ name: "CreditRefinanceList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_credit = this.credit == null ? true : false;
  this.errors.balance = this.credit_refinance.balance.length == 0 ? true : false;
  this.errors.payment_date = this.credit_refinance.payment_date.length == 0 ? true : false;
  this.errors.new_payment_date = this.credit_refinance.new_payment_date.length == 0 ? true : false;
  this.errors.dues = this.credit_refinance.dues.length == 0 ? true : false;

  if (this.credit_refinance.product_type == 2) {
    for (let index = 0; index < this.credit_refinance.payment_capital.length; index++) {
      const element = this.credit_refinance.payment_capital[index];
      if (element.dues.length == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }
      if (parseFloat(element.dues) == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }

      if (element.amount.length == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }
      if (parseFloat(element.amount) == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }
    }
  }

  if (this.errors.id_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.balance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.new_payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  if (parseFloat(this.credit_refinance.total) > parseFloat(this.credit_refinance.balance)) {
    this.validate = true; 
    Swal.fire({ icon: 'warning', text: 'El monto de la amortización no debe superar al saldo capital', timer: 2000,}); 
    return false;
  }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el refinanciamiento del crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCreditRefinance();
    }
  });
}

function GetCreditByID() {
  let me = this;
  me.credit_refinance.id_credit = '';
  me.credit_refinance.id_credit_detail = '';
  me.credit_refinance.quota_number = '';
  me.credit_refinance.balance = '0.00';
  me.credit_refinance.interest = '';
  me.credit_refinance.quota = '0.00';
  me.credit_refinance.total = '0.00';
  me.credit_refinance.amortization = '0.00';
  me.credit_refinance.credits_detail = [];
  me.credit_refinance.credits_details = [];
  me.credit_refinance.payment_capital = [];
  me.credit_refinance.capital_diference = '0.00';
  if (this.credit == null) {
    return false;
  }
  let url = me.url_base + "credit-amortization/credit-by-id";

  let data = {
    id_credit : this.credit.value,
  }
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_refinance.id_credit = response.data.result.credit.id_credit;
        me.credit_refinance.id_product = response.data.result.credit.id_product;
        me.credit_refinance.product_type = response.data.result.credit.product_name
        
        me.credit_refinance.payment_date = response.data.result.credit_detail.date;
        me.credit_refinance.new_payment_date = response.data.result.credit_detail.date;
        me.credit_refinance.quota_number = response.data.result.credit_detail.quota_number;
        me.credit_refinance.balance = response.data.result.credit_detail.balance;
        me.credit_refinance.interest = response.data.result.credit_detail.interest;
        me.credit_refinance.quota = '0.00';
        me.credit_refinance.id_credit_detail = response.data.result.credit_detail.id_credit_detail;
        me.credit_refinance.total = '0.00';
        
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    });
}


function ValidateSimulate() {

  this.credit_refinance.credit_detail = [];
  this.errors.id_credit = this.credit == null ? true : false;
  this.errors.balance = this.credit_refinance.balance.length == 0 ? true : false;
  this.errors.payment_date = this.credit_refinance.payment_date.length == 0 ? true : false;
  this.errors.new_payment_date = this.credit_refinance.new_payment_date.length == 0 ? true : false;
  this.errors.dues = this.credit_refinance.dues.length == 0 ? true : false;

  if (this.credit_refinance.product_type == 2) {
    for (let index = 0; index < this.credit_refinance.payment_capital.length; index++) {
      const element = this.credit_refinance.payment_capital[index];
      if (element.dues.length == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }
      if (parseFloat(element.dues) == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }

      if (element.amount.length == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }
      if (parseFloat(element.amount) == 0) {
        Swal.fire({ icon: 'warning', text: 'Verifique que esten llenados correctamente los pagos del capital', timer: 2000,}); 
        return false;
      }
    }
  }

  if (this.errors.id_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.balance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.new_payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  this.Simulation();
}

function Simulation() {
  let me = this;
  let url = me.url_base + "credit-refinance/simulate-credit";
  let data = me.credit_refinance;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.credit_refinance.loan_detail = response.data.result;
    }else{
      if (me.credit.id_product == 2) {
        me.modal_payment_capital = true;
      }
      Swal.fire({ icon: 'error', text: response.data.message, timer: 2000,}); 
    }

  });
}

</script>
